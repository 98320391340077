.content-wrapper {
  margin-top: 50px;
  display: flex;
  gap: 25px;
  flex-direction: column;

  .product-block {
    display: flex;
    gap: 110px;

    .slider {
      width: 639px;
      padding: 10px;

      .image {
        width: 100%;
        max-height: 543px;
        height: 500px;
        object-fit: contain;
      }
    }
  }

  .product-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px;
  }

  .count-product {
    color: #d9d9d9;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .color-block {
    width: 53px;
    height: 53px;
    border-radius: 5px;
  }

  .size-block {
    width: 99px;
    height: 53px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .size-letter {
      font-size: 24px;
      font-weight: 700;
      line-height: 29.05px;
    }

    .size-description {
      font-size: 10px;
      font-weight: 400;
      line-height: 12.1px;
    }
  }

  .small-white-button {
    padding: 3px 16px;
    border-radius: 12px;
    background-color: white;
    color: black;
    font-size: 18px;
    border: 2px solid white;
  }

  .activePr {
    border: 2px solid #1fb73a;
  }

  .description-block {
    max-width: 731px;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 21.78px;
      margin: 0;
      padding: 0;
    }
  }

  .request-word {
    background-color: #494949;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
    padding: 3px 16px;
    color: #d9d9d9;
    border-radius: 10px;
  }

  .cart-item-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 125px;
    background-color: #222222;
    align-items: center;
    border-radius: 9px;
    height: 42px;
    color: white;
  }
}

.sub-title {
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin: 0;
}

.slider {
  .slick-dots {
    display: flex !important;

    flex-direction: row;
    gap: 10px;
    bottom: -35px;

    li {
      width: 100% !important;

      &.slick-active {
        button {
          &::before {
            color: transparent !important;
            background-color: #1fb73a;
          }
        }
      }

      button {
        width: 100% !important;

        &::before {
          color: transparent !important;
          background-color: #a5a5a5;
          width: 100% !important;
          height: 10px !important;
          border-radius: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-block {
    flex-direction: column;

    .slider {
      padding: 0 !important;
      width: 100% !important;

      .slick-arrow.slick-next {
        position: absolute;
        top: 50%;
        right: 20px;
        color: white;
        background-color: black;
        border-radius: 50%;
        display: grid;
        place-items: center;
        padding-top: 1.43px;
      }

      .slick-arrow.slick-prev {
        left: 20px;
        z-index: 999 !important;
        color: #fff;
        background-color: black;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        object-fit: contain !important;
        width: 100% !important;
      }
    }
  }

  .content-wrapper .flex {
    padding-left: 10px;
  }

  .container.box {
    padding-bottom: 20px;
  }
}
