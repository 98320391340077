.cart-container {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  gap: 15px;
}

.counter-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  background: #4a4a4a;
  border-radius: 10px;
  padding: 15px;
}

.counter-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0;
}

.counter-icon {
  width: 16px;
  height: 16px;
}

.counter-value {
  color: white;
  font-weight: bold;
  min-width: 2rem;
  text-align: center;
}

.add-button {
  background: #2aab3f;
  color: white;
  border: none;
  border-radius: 6px;
  width: 269px;
  height: 60px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;

  font-size: 18px;
  font-weight: 500;
  line-height: 21px;

  .price-span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }
}

.out-of-stock-button {
  background: #cccccc;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.75rem 1.5rem;
  cursor: not-allowed;
  font-weight: bold;
}

.price-block {
  display: flex;
  gap: 7px;
  align-items: center;

  h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 21px;
    color: #ffffff;
  }

  .old-price {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-decoration-line: line-through;
    text-decoration-skip-ink: none;
    color: #7d7d7d;
  }

  .percent {
    background-color: #fd3979;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
    padding: 0 8px;
    color: white;
    border-radius: 6px;
  }
}

@media screen and (max-width: 768px) {
  .container.box {
    padding-bottom: 100px;
  }
  .cart-container {
    display: flex;
    flex-direction: row-reverse;
    background-color: rgb(40, 40, 40);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
  }
  .price-block {
    order: 1;
    width: auto;

    h3 {
      font-size: 23px;
    }
  }

  .old-price {
    display: none;
  }

  .counter-container {
    order: 1;
    padding: 10px;
    gap: 10px;
  }
  .add-button {
    flex: 1;
    width: auto;
  }

  .container > footer {
    display: none;
  }
}
