.dropdown .menu_item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 6px;
  p.category {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    padding: 12px;
    padding-left: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  p.subcategory {
    width: 95%;
    background: rgba(0, 0, 0, 0.2);
    padding: 12px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    cursor: pointer;
  }
}
