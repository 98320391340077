.orderInfo {
  .order-info-price {
    font-size: 24px;
    font-weight: 700;
    line-height: 21px;
  }

  .cart-item-label {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h3 {
      margin: 0;
      line-height: 20px;
    }
  }
}

.card-block {
  padding: 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  .receipt {
    background-color: #262626;
    width: 30%;
    border-radius: 25px;
    padding: 25px;
    height: 160px;
  }

  .date {
    color: #fff;
    font-weight: 550;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .item-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .item-label {
    color: #808080;
    font-size: 14px;
  }
  .item-amount {
    color: #808080;
    font-size: 14px;
    text-align: right;
  }

  .total-row {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
  }

  .total-label {
    color: #ffffff;
  }

  .total-amount {
    color: #ffffff;
  }
}
